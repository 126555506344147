import _ from 'lodash';

export const requestMediaCamera = async (app = 'patient') => {
  const responses = {};
  // 1. Check if the browser detects devices
  const enumerateDevices = navigator.mediaDevices.enumerateDevices().then((devices) => {
    const audioInputDevices: any[] = [];
    const videoInputDevices: any[] = [];

    devices.forEach((device) => {
      if (device.kind === 'audioinput') {
        audioInputDevices.push('audio');
      }
      if (device.kind === 'videoinput') {
        videoInputDevices.push('video');
      }
    });
    if (audioInputDevices.length === 0) {
      _.set(responses, 'noAudioInput', true);
    }
    if (videoInputDevices.length === 0) {
      _.set(responses, 'noVideoInput', true);
    }
  });

  if (Object.keys(responses).length > 0) {
    return responses;
  }

  if (Object.keys(responses).length === 0) {
    // 2. Check if the browser has permissions
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (audioStream.getAudioTracks().length < 1) {
        _.set(responses, 'noAudioStreamLength', true);
      } else {
        audioStream.getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.stop();
          }
        });
      }
    } catch {
      _.set(responses, 'noAudioStreamLength', true);
      console.warn(`User Media NOT ok`);
      const pathParts = window.location.pathname;
      if (pathParts.indexOf('/support/permissions') === -1) {
        window.location.href = '/support/permissions?d=f';
      }
    }

    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoStream.getVideoTracks().length < 1) {
        _.set(responses, 'noVideoStreamLength', true);
      } else {
        videoStream.getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.stop();
          }
        });
      }
    } catch {
      _.set(responses, 'noVideoStreamLength', true);
      console.warn(`User Media NOT ok`);
      const pathParts = window.location.pathname;
      if (pathParts.indexOf('/support/permissions') === -1) {
        window.location.href = '/support/permissions?d=f';
      }
    }
    return responses;
  }
  return responses;
};
