import 'doctor/styles/call-alert-modal.css';
import 'doctor/styles/responsive.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu';
import FooterSection from 'shared/components/FooterSection';
import DoctorTopMenu from 'doctor/components/DoctorTopMenu';
import { DoctorMenuRoutes, DoctorRoutes } from 'doctor/DoctorRoutes';
import {
  getAuthState,
  getDoctorAppointmentDetailsState,
  getDoctorAppointmentState,
  getPushesState,
  getSharedState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import {
  isLoginOrRegister,
  isRegisterSteps,
  isPermissionsScreen,
  isLandingPage,
  isTwilioScreen,
  mainContentAlignCenter,
  activateAccount,
} from 'shared/services/EnvService';
import {
  doctorAcceptsCallRequest,
  getCallSpecialistInfoRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import { requestLogout } from 'shared/redux/actions/AuthActions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import {
  registerDoctorLocalEvents,
  removeDoctorLocalEvents,
} from 'shared/modules/DoctorEventsListener';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Avatar from 'shared/components/Avatar';
import Utils from 'shared/modules/Utils';
import { getCurrentAppointmentRequest } from 'shared/redux/actions/AppointmentActions';
import { debounce } from 'lodash';
import { requestMediaCamera } from '../shared/modules/PermissionsUtils';
import NavigationService from '../shared/services/NavigationService';

const DoctorApp = () => {
  const { t } = useTranslation();
  const goToBlog = () => window.open(`https://blog.ringdoc.ro`, '_self');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, isProfileCompleted, userData } = useSelector(getAuthState, shallowEqual);
  const { unseen = 0 } = useSelector(getPushesState, shallowEqual);
  const { showWaitingTime } = useSelector(getSharedState, shallowEqual);
  const { appointmentId, patient = { firstName: '', lastName: '', birthDate: '' } } = useSelector(
    getDoctorAppointmentState,
    shallowEqual,
  );
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const [disabledButton, setDisabledButton] = useState(false);

  const [firstName, setFirstName] = useState(profileData.firstName);
  const [lastName, setLastName] = useState(profileData.lastName);
  const [profilePicture, setProfilePicture] = useState(profileData.pictureMedia);
  const { medicalRecords } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { birthDate = '' } = patient;

  const validatePermissions = async () => {
    if (['/doctor/login'].indexOf(location.pathname) === -1) {
      const result = await Notification.permission;
      setTimeout(() => {
        if (result !== 'granted') {
          NavigationService.navigate('/support/permissions?d=d', { state: 'doctor' });
        }
      }, 500);

      requestMediaCamera('doctor').then((response) => {
        if (!response) {
          NavigationService.navigate('/support/permissions?d=d', { state: 'doctor' });
        }
      });
    }
  };

  const renderSubTitle = () => {
    if (medicalRecords?.length) {
      return (
        <div className="year-files">
          {Utils.calculateAge(birthDate) > 0 && `${Utils.calculateAge(birthDate)} ${t('years')} • `}
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="15" icon="Document" />
          {t('attachedFiles')}
        </div>
      );
    }
    if (Utils.calculateAge(birthDate) > 0) {
      return <div className="year-files">{`${Utils.calculateAge(birthDate)} ${t('years')}`}</div>;
    }
    return null;
  };
  const [timeEstimation, setTimeEstimation] = useState<any>(1);

  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(requestLogout({}));
    navigate('/doctor');
  };
  const takeAction = () => {
    dispatch(
      doctorAcceptsCallRequest({
        data: { appointmentId, timeStart: timeEstimation },
      }),
    );
    setTimeEstimation(1);
    setTimeout(() => {
      setDisabledButton(false);
    }, 2000);
  };

  useEffect(() => {
    validatePermissions();
    registerDoctorLocalEvents();
    setTimeEstimation(1);

    return () => {
      removeDoctorLocalEvents();
    };
  }, []);

  useEffect(() => {
    validatePermissions();
  }, [location.pathname]);

  const [isPageVisibleAgain, setIsPageVisibleAgain] = useState(false);
  const onVisibilityChange = () => setIsPageVisibleAgain(Utils.getIsDocumentHidden());

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        dispatch(fetchUserProfileRequest({}));
        dispatch(getCurrentAppointmentRequest({}));
      })();

      document.addEventListener(
        Utils.getBrowserVisibilityProp(document),
        onVisibilityChange,
        false,
      );

      return () => {
        document.removeEventListener(Utils.getBrowserVisibilityProp(document), onVisibilityChange);
      };
    }

    return () => {};
  }, [isLoggedIn]);

  useEffect(() => {
    if (isPageVisibleAgain) {
      dispatch(getCurrentAppointmentRequest({}));
      dispatch(getCallSpecialistInfoRequest({}));
    }
  }, [isPageVisibleAgain]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isProfileCompleted === false) {
        const wantMLMSession = sessionStorage.getItem('doctorWantMLM');

        if (
          (!userData.referrer && wantMLMSession === null) ||
          (!userData.referrer && wantMLMSession === 'true')
        ) {
          navigate('/doctor/mlm');
        } else {
          navigate('/doctor/register-step-personal-data');
        }
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [isProfileCompleted]);

  const maintenanceMode = false;
  const DoctorTag = () => <div id="isDoctorApp" />;
  return maintenanceMode || isPermissionsScreen() ? (
    <div className="">
      <DoctorTag />
      <DoctorRoutes isLoggedIn={isLoggedIn} maintenanceMode={maintenanceMode} />
    </div>
  ) : (
    <div className="doctor-app d-flex flex-column min-vh-100">
      <DoctorTag />

      {isLandingPage() && (
        <Menu right burgerButtonClassName="mobile-menu">
          <ul>
            <li>
              <Link className="cursor-pointer" to="/">
                {t('mainScreen')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="about-us">
                {t('aboutUs')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
            <li>
              <div className="cursor-pointer" onClick={goToBlog}>
                {t('blog')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </div>
            </li>
            <li>
              <Link className="cursor-pointer" to="contact">
                {t('contact')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
            {!isLoggedIn && (
              <>
                <li className="auth">
                  <Link className="cursor-pointer" to="/doctor/login">
                    {t('doctorApp.login')}
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="20"
                      icon="Right_pointer"
                    />
                  </Link>
                </li>
                <li>
                  <Link className="cursor-pointer" to="/">
                    {t('imAPatient')}
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="20"
                      icon="Right_pointer"
                    />
                  </Link>
                </li>
              </>
            )}
            {isLoggedIn && (
              <li className="my-account">
                <Link className="cursor-pointer" to="/doctor/home">
                  <div className="avatar-section">
                    <Avatar
                      size={25}
                      imageURL={profilePicture}
                      firstName={firstName}
                      lastName={lastName}
                    />
                    Dr. {lastName}
                  </div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="20"
                    icon="Right_pointer"
                  />
                </Link>
              </li>
            )}
          </ul>
        </Menu>
      )}

      {isLoginOrRegister() && (
        <Menu right burgerButtonClassName="mobile-menu">
          <ul>
            <li className="login">
              <Link className="cursor-pointer" to="/doctor/landing">
                {t('doctorApp.login')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
            <li className="register">
              <Link className="cursor-pointer" to="/doctor/register">
                {t('doctorApp.createAccount')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="/">
                {t('imAPatient')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
          </ul>
        </Menu>
      )}

      {isRegisterSteps() && (
        <Menu right burgerButtonClassName="mobile-menu">
          <ul>
            <li className="login">
              <Link className="cursor-pointer" to="/" onClick={onLogOut}>
                {t('logoutButton')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="/">
                {t('imAPatient')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
          </ul>
        </Menu>
      )}

      {isLoggedIn && !isLandingPage() && !isPermissionsScreen() && !isRegisterSteps() && (
        <div className="mobile-menu-logged-in">
          <DoctorTopMenu routes={DoctorMenuRoutes} unseen={unseen} isMobile />
        </div>
      )}

      <div className="container-fluid d-flex flex-column min-vh-100">
        <div className="row d-flex flex-column min-vh-100">
          <header className="menu landing menu-medic">
            <div className="container">
              <div className="row">
                {isLandingPage() && (
                  <div className="view landing-menu doctor-menu-wrapper">
                    <Link className="logo cursor-pointer" to="/doctor/home">
                      <i className="ringdoc-medic-color-logo" />
                    </Link>
                    <div className="menu doctor-menu">
                      <ul>
                        <li>
                          <Link className="cursor-pointer" to="/">
                            {t('mainScreen')}
                          </Link>
                        </li>
                        <li>
                          <Link className="cursor-pointer" to="about-us">
                            {t('aboutUs')}
                          </Link>
                        </li>
                        <li>
                          <div className="cursor-pointer" onClick={goToBlog}>
                            {t('blog')}
                          </div>
                        </li>
                        <li>
                          <Link className="cursor-pointer" to="contact">
                            {t('contact')}
                          </Link>
                        </li>
                        {!isLoggedIn && (
                          <>
                            <li className="auth">
                              <Link to="/doctor/login"> {t('doctorApp.login')}</Link>
                            </li>
                            <li>
                              <Link className="cursor-pointer" to="/">
                                {t('imAPatient')}
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="20"
                                  icon="Right_pointer"
                                />
                              </Link>
                            </li>
                          </>
                        )}
                        {isLoggedIn && (
                          <li className="my-account">
                            <Link to="/doctor/home">
                              <div className="avatar-section">
                                <Avatar
                                  size={18}
                                  imageURL={profilePicture}
                                  firstName={firstName}
                                  lastName={lastName}
                                />
                              </div>
                              Dr. {lastName}
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="20"
                                icon="Right_pointer"
                              />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}

                {isLoginOrRegister() && (
                  <div className="view login-register-menu green-menu doctor-menu-wrapper">
                    <Link className="logo cursor-pointer" to="/doctor/landing">
                      <i className="ringdoc-medic-color-logo" />
                    </Link>
                    <div className="menu doctor-menu">
                      <ul>
                        <li className="login">
                          <Link to="/doctor/login">{t('doctorApp.login')}</Link>
                        </li>
                        <li className="register">
                          <Link to="/doctor/register">{t('doctorApp.createAccount')}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {isRegisterSteps() && (
                  <div className="view login-register-menu green-menu doctor-menu-wrapper">
                    <Link className="logo cursor-pointer" to="/doctor/home">
                      <i className="ringdoc-medic-color-logo" />
                    </Link>
                    <div className="menu doctor-menu">
                      <ul>
                        <li className="login">
                          <Link to="/" onClick={onLogOut}>
                            {t('logoutButton')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {isLoggedIn && !isLandingPage() && !isPermissionsScreen() && !isRegisterSteps() && (
                  <div className="view is-logged-menu doctor-menu-wrapper">
                    <div className="menu">
                      <Link
                        className="logo cursor-pointer"
                        to={isTwilioScreen() ? 'video-call' : '/doctor/home'}
                      >
                        <i className="ringdoc-medic-color-logo" />
                      </Link>
                      {!activateAccount() && (
                        <DoctorTopMenu routes={DoctorMenuRoutes} unseen={unseen} isMobile={false} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>

          <main
            className={`${
              mainContentAlignCenter() ? 'container mt-auto mb-auto' : 'container mb-auto'
            }`}
          >
            <DoctorRoutes isLoggedIn={isLoggedIn} />
            <div className="push" />
          </main>

          <FooterSection />
        </div>
      </div>

      <Modal
        show={showWaitingTime}
        animation
        centered
        backdrop="static"
        className="modal-time-waiting medic-modal"
      >
        <Modal.Header>
          <Modal.Title>{t('waitingTime')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="patient-box">
            <div className="avatar-section">
              <Avatar
                size={36}
                imageURL={patient.pictureMedia ?? patient?.picture}
                firstName={patient.firstName}
                lastName={patient.lastName}
              />
            </div>
            <div className="info">
              <div className="fullName">{`${patient.firstName} ${patient.lastName}`}</div>
              {renderSubTitle()}
            </div>
          </div>
          <div className="minutes">
            <div className="form-select-label">
              <select
                value={timeEstimation}
                onChange={(e) => setTimeEstimation(e?.target?.value)}
                required
                id="min"
                name="min"
                className="custom-select"
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>10</option>
              </select>
            </div>
            <div className="min">{timeEstimation > 1 ? t('minutes') : t('minute')}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setDisabledButton(true);
              debounce(takeAction, 1001)();
            }}
            type="button"
            className="green-button"
            disabled={disabledButton}
          >
            {t('confirmWaitingTime')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DoctorApp;
