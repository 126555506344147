import React, { useState } from 'react';
import AgoraRTC, { AgoraRTCProvider, useRTCClient } from 'agora-rtc-react';
import config from './config';
import AgoraManager from './AgoraManager';

export function AgoraVideoProvider({ options, children, onError = () => {} }) {
  const agoraEngine = useRTCClient(
    AgoraRTC.createClient({ codec: 'vp8', mode: config.selectedProduct }),
  );

  AgoraRTC.setLogLevel(4);

  return (
    <AgoraRTCProvider client={agoraEngine}>
      <AgoraManager>{children}</AgoraManager>
    </AgoraRTCProvider>
  );
}
