import 'shared/styles/permissions.css';
import { getEnv } from 'shared/services/EnvService';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-grid-carousel';
import chromeMic from 'shared/assets/images/chrome-mic.png';
import chromeCam from 'shared/assets/images/chrome-cam.png';
import chromeNot from 'shared/assets/images/chrome-not.png';
import chromeAll from 'shared/assets/images/chrome-all.png';
import ffMic from 'shared/assets/images/ff-mic.png';
import ffCam from 'shared/assets/images/ff-cam.png';
import ffNot from 'shared/assets/images/ff-not.png';
import ffAll from 'shared/assets/images/ff-all.png';
import Utils from '../../modules/Utils';
import { requestMediaCamera } from '../../modules/PermissionsUtils';

const PermissionsScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [missingCameraNotificationText, setMissingCameraNotificationText] = useState('');
  const [missingMicrophoneNotificationText, setMissingMicrophoneNotificationText] = useState('');
  const [cameraNotificationText, setCameraNotificationText] = useState('');
  const [microphoneNotificationText, setMicrophoneNotificationText] = useState('');
  const [notificationText, setNotificationText] = useState('');
  const [activatedPermissionsText, setActivatedPermissionsText] = useState('');

  const destination = searchParams.get('d');

  let isFirefox = false;
  const sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf('Firefox') > -1 || sUsrAg.indexOf('FxiOS') > -1) {
    isFirefox = true;
  }

  useEffect(() => {
    (async () => {
      const localResponseToken = {};

      if (destination === 'd' || destination === 'f') {
        const camMicPermissions = await requestMediaCamera();
        Object.entries(camMicPermissions).forEach((row) => {
          if (row[1] === true || row[1] === null) {
            _.set(localResponseToken, row[0], true);
          }
        });
      }

      const responseWebToken = await firebase.getWebToken();
      Object.entries(responseWebToken).forEach((row) => {
        if (row[1] === true || row[1] === null) {
          _.set(localResponseToken, row[0], true);
        }
      });
      return localResponseToken;
    })().then((localResponseToken) => {
      let havePermissions = true;

      if (destination === 'd' || destination === 'f') {
        Object.entries(localResponseToken).forEach((row) => {
          if (row[0] === 'noAudioInput') {
            setMissingMicrophoneNotificationText(
              t('permissions.missingMicrophoneNotificationText'),
            );
            havePermissions = false;
          }

          if (row[0] === 'noVideoInput') {
            setMissingCameraNotificationText(t('permissions.missingCameraNotificationText'));
            havePermissions = false;
          }

          if (row[0] === 'noAudioStreamLength') {
            setMicrophoneNotificationText(t('permissions.microphoneNotificationText'));
            havePermissions = false;
          }

          if (row[0] === 'noVideoStreamLength') {
            setCameraNotificationText(t('permissions.cameraNotificationText'));
            havePermissions = false;
          }

          if (row[0] === 'noToken') {
            setNotificationText(t('permissions.notificationText'));
            havePermissions = false;
          }
        });
      }

      if (havePermissions) {
        setActivatedPermissionsText(t('permissions.activatedPermissionsText'));
      }
    });

    return () => {};
  }, []);

  const acceptPermissions = () => {
    if (destination === 'd') {
      window.location.href = '/doctor/login';
    } else {
      window.location.href = '/patient/login';
    }
  };

  const svgSize = Utils.isMobileDevice() ? '20' : '35';

  const renderArrowLeft = () => {
    return (
      <div className="prev-slider">
        <CustomIcon
          className="custom-icon rotate-180"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };
  const renderArrowRight = () => {
    return (
      <div className="next-slider">
        <CustomIcon
          className="custom-icon"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };

  return (
    <div
      className="permissions-bg"
      style={{
        backgroundColor:
          getEnv('APP_TYPE', true) === 'patient' ? 'rgba(0, 19, 21, 0.5)' : 'rgba(0, 19, 21, 0.5)',
      }}
    >
      {/* {!Utils.isMobileDevice() && ( */}
      {/*  <div className="circle-arrow-permission"> */}
      {/*    <CustomIcon className="custom-icon" color={Colors.darkGrey} size="50" icon="Left_arrow" /> */}
      {/*  </div> */}
      {/* )} */}
      <i className="big-arrow-permissions-icon" />
      <section className="h-100">
        <header className="container h-100">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="permissions-page">
              <div className="left-column">
                <h2>Acces permisiuni</h2>
                <ul>
                  {missingMicrophoneNotificationText !== '' ||
                  missingCameraNotificationText !== '' ||
                  microphoneNotificationText !== '' ||
                  cameraNotificationText !== '' ||
                  notificationText !== '' ||
                  activatedPermissionsText !== '' ? (
                    <>
                      {missingMicrophoneNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Microphone"
                            />
                          </div>
                          <span>{missingMicrophoneNotificationText}</span>
                        </li>
                      )}
                      {missingCameraNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Video"
                            />
                          </div>
                          <span>{missingCameraNotificationText}</span>
                        </li>
                      )}
                      {microphoneNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Microphone"
                            />
                          </div>
                          <span>{microphoneNotificationText}</span>
                        </li>
                      )}
                      {cameraNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Video"
                            />
                          </div>
                          <span>{cameraNotificationText}</span>
                        </li>
                      )}
                      {notificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Notifications"
                            />
                          </div>
                          <span>{notificationText}</span>
                        </li>
                      )}
                      {activatedPermissionsText !== '' && (
                        <li className="mb-5">
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Check"
                            />
                          </div>
                          <span>{t('permissions.activatedPermissions')}</span>
                        </li>
                      )}
                    </>
                  ) : (
                    <li className="mb-5">
                      <div className="circle-icon">
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.patientColor}
                          size={svgSize}
                          icon="Transactions_Arrows"
                        />
                      </div>
                      <span>{t('permissions.checkingPermissionsText')}</span>
                    </li>
                  )}
                </ul>
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn-activation" onClick={acceptPermissions}>
                  {t('permissions.reloadPage')}
                </button>
              </div>
              <div className="right-column">
                {activatedPermissionsText === '' && (
                  <div className="permissions-carousel">
                    <Carousel
                      cols={1}
                      rows={1}
                      gap={10}
                      loop
                      autoplay={5000}
                      hideArrow
                      arrowLeft={renderArrowLeft}
                      arrowRight={renderArrowRight}
                      responsiveLayout={[
                        {
                          breakpoint: 1200,
                          cols: 1,
                        },
                        {
                          breakpoint: 990,
                          cols: 1,
                        },
                        {
                          breakpoint: 670,
                          cols: 1,
                        },
                      ]}
                      mobileBreakpoint={100}
                    >
                      <Carousel.Item>
                        <img
                          className="permissions-carousel-image"
                          src={isFirefox ? ffMic : chromeMic}
                          alt="Mic"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="permissions-carousel-image"
                          src={isFirefox ? ffCam : chromeCam}
                          alt="Cam"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="permissions-carousel-image"
                          src={isFirefox ? ffNot : chromeNot}
                          alt="Not"
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                )}
                {activatedPermissionsText !== '' && (
                  <div className="permissions-carousel">
                    <Carousel
                      cols={1}
                      rows={1}
                      gap={10}
                      loop
                      hideArrow
                      responsiveLayout={[
                        {
                          breakpoint: 1200,
                          cols: 1,
                        },
                        {
                          breakpoint: 990,
                          cols: 1,
                        },
                        {
                          breakpoint: 670,
                          cols: 1,
                        },
                      ]}
                      mobileBreakpoint={100}
                    >
                      <Carousel.Item>
                        <img
                          className="permissions-carousel-image"
                          src={isFirefox ? ffAll : chromeAll}
                          alt="Mic"
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      </section>
    </div>
  );
};

export default PermissionsScreen;
