import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import AppointmentConclusion from '../../screens/appointment/AppointmentConclusion';
import {
  getDoctorAppointmentDetailsState,
  getUserProfileState,
} from '../../../shared/redux/src/StatesGetter';

const DoctorConsultationDetailsTabRecommendation = () => {
  const doctor = useSelector(getUserProfileState, shallowEqual);
  const {
    conclusion,
    serverStatus,
    paymentStatus,
    id: appointmentId,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);

  return (
    <AppointmentConclusion
      appointmentId={appointmentId}
      conclusion={conclusion}
      serverStatus={serverStatus}
      appointmentStep="DetailsTabFolder"
      doctor={doctor}
      paymentStatus={paymentStatus}
    />
  );
};

export default DoctorConsultationDetailsTabRecommendation;
